import './App.css';

function App() {
  return (
    <div class="landing-page">
      <header>
        <div class="container">
          <a href="/" class="logo"><b>Unvexed</b></a>
          <ul class="links">
            <li>Home</li>
            <li>About Us</li>
            <li>Work</li>
            <li>Info</li>
            <li>Get Started</li>
          </ul>
        </div>
      </header>
      <div class="content">
        <div class="container">
          <div class="info">
            <h1>Unvexed</h1>
            <p>About Lines</p>
            <button>Get Started</button>
          </div>
          <div class="image">
            <img className='hoe' src="https://i.postimg.cc/65QxYYzh/001234.png" alt="homepageimg"/>
          </div>
        </div>
      </div>
      <div class="footer"><p>© 2024 Unvexed</p></div>
    </div>
  );
}

export default App;
